.collapse-enter {
  max-height: 0;
  opacity: 0;
}

.collapse-enter-active {
  max-height: 500px; /* Arbitrary large value */
  opacity: 1;
  transition: max-height 300ms ease-in, opacity 300ms ease-in;
}

.collapse-exit {
  max-height: 500px;
  opacity: 1;
}

.collapse-exit-active {
  max-height: 0;
  opacity: 0;
  transition: max-height 300ms ease-out, opacity 300ms ease-out;
}

.compact-app {
  .check-list-steps-container {
    max-height: 0px;
    transition: all 0.2s linear;
  }

  :hover {
    .check-list-steps-container {
      max-height: 1000px;
    }
  }
}
