.user-avatar {
  position: relative;
  .badge {
    right: 0;
    top: 2px;
    border-radius: 50%;
  }
  .avatar-container {
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    &.online:before {
      content: "";
      position: absolute;
      bottom: 5%;
      right: 5%;
      width: 30%;
      height: 30%;
      max-width: 15px;
      max-height: 15px;
      border-radius: 100%;
      border: 2px solid #fff;
      background-color: #4caf50;
      z-index: 5;
    }
  }
}
