@import "@fontsource/open-sans/400.css";
@import "@fontsource/open-sans/600.css";

body {
  width: 100%;
  // height: 100vh; // Safari causes issue
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
}

#root {
  overflow: hidden;
  max-width: 600px;
  > div > .left,
  .right {
    overflow: hidden;
    max-width: 600px;
    width: 100%;
    height: 100%;
    margin: auto;
    position: relative;
  }
}

html {
  // font-size: 14.5px;
  font-size: 14px;
  line-height: 1.4;
  font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

@media (max-device-width: 1360px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 1360px) and(max-height: 680px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 1360px) and(max-height: 600px) {
  html {
    font-size: 13px;
  }
}

@media (max-device-width: 600px) {
  html {
    font-size: 13px;
  }
}

// @media (max-height: 620px) {
//   body {
//     zoom: 0.83;
//     // chat footer
//     .chat-footer {
//       zoom: 1.2 !important;
//     }
//   }
// }

// @media (max-height: 550px) {
//   body {
//     zoom: 0.78;
//     // chat footer
//     .chat-footer {
//       zoom: 1.2 !important;
//     }
//   }
// }

// // chat footer
// @media (max-width: 550px) {
//   body {
//     .chat-footer {
//       zoom: 1.1;
//     }
//   }
// }

// @media (max-width: 1360px) and(max-height: 680px) {
//   body {
//     zoom: 0.83;
//     // chat footer
//     .chat-footer {
//       zoom: 1.2 !important;
//     }
//   }
// }

// @media (max-height: 620px) {
//   body {
//     zoom: 0.83;
//     // chat footer
//     .chat-footer {
//       zoom: 1.2 !important;
//     }
//   }
// }

// @media (max-height: 550px) {
//   body {
//     zoom: 0.78;
//     // chat footer
//     .chat-footer {
//       zoom: 1.2 !important;
//     }
//   }
// }

// // chat footer
// @media (max-width: 550px) {
//   body {
//     .chat-footer {
//       zoom: 1.1;
//     }
//   }
// }

.bg-light-gray {
  background: #f4f4f4;
}
.page-overflow-container {
  overflow-y: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

// .page-overflow-container.hide-scroll-bar::-webkit-scrollbar {
//   display: none;
// }

.disconnected-bar {
  background: #222;
  color: #fff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

svg.icon-svg {
  fill: currentColor;
}

.modified-header-style-1 {
  border-radius: 0 0 10px 10px;
  box-shadow: 0 0 20px #00000069;
}

.modified-header-style-2 {
  border-radius: 10px;
  box-shadow: 0 0 20px #00000069;
  margin: 8px;
}

.max-container {
  max-width: 600px;
  margin: auto;
}

.text-overflow-ellipses {
  text-overflow: ellipsis;
}
.rounded-lg {
  border-radius: 0.5rem /* 8px */;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-right-top {
  background-position: right top !important;
}

.bg-small {
  background-size: 4rem /* 64px */ !important;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* Number of lines to show */
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* Number of lines to show */
}
/*=== Enter Animation Classed  ===*/

.animated-cc {
  animation-duration: 0.2s;
  animation-fill-mode: both;
}

.animate {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
}

.animate-slow {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
}

.animate-fast {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
}

.animate-duration-500 {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate-duration-1s {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/*=== Optional Delays, ===*/
.delay-500 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.delay-1000 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.delay-1500 {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.delay-2000 {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  animation-delay: 2s;
}

.switch .switch__gfx {
  border: 1px solid #ccc;
}

/*==== FADE IN UP ===*/

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fade-in-up {
  animation-name: fade-in-up;
}

/*=== FADE IN DOWN ===*/

@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fade-in-down {
  animation-name: fade-in-down;
}

/*=== FADE IN LEFT ===*/
@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fade-in-left {
  animation-name: fade-in-left;
}

/*==== FADE IN RIGHT ===*/

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fade-in-right {
  animation-name: fade-in-right;
}

/*=== FADE IN  ===*/

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.fade-in {
  animation-name: fade-in;
}

@keyframes cc-expand-bounce-left {
  0% {
    transform: scale(0);
    opacity: 0;
    transform-origin: left;
  }
  80% {
    transform: scale(1.1);
    opacity: 0.9;
    transform-origin: left;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    transform-origin: left;
  }
}

@keyframes cc-expand-bounce-right {
  0% {
    transform: scale(0);
    opacity: 0;
    transform-origin: right;
  }
  80% {
    transform: scale(1.1);
    opacity: 0.9;
    transform-origin: right;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    transform-origin: right;
  }
}

/* Here the scale simply goes from 1 back to 0 */
@keyframes cc-shrink {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.cc-expand-bounce-left {
  animation-name: cc-expand-bounce-left;
  animation-duration: 0.25s;
}

.cc-expand-bounce-right {
  animation-name: cc-expand-bounce-right;
  animation-duration: 0.25s;
}

.cc-shrink {
  animation-name: cc-shrink;
  animation-duration: 0.1s;
}

/* custom scrollbar */
::-webkit-scrollbar {
  // width: 7px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #0000001c;
  border-radius: 20px;
  border: 0px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #00000030;
}

/*=== Mobile Patch  ===*/

@media only screen and (max-device-width: 600px) {
  .top-menu {
    margin-right: 40px;
  }
}

// Fix for IOS 16px Input Zoom Issue
// @supports (-webkit-touch-callout: none) {
//   input {
//     font-size: 16px;
//     scale: 0.75;
//     transform-origin: left top;
//     margin-bottom: -10px;
//     margin-right: -33.333333333%;
//   }
// }

.group {
  &:hover,
  &:active {
    .group-hover\:show {
      display: block !important;
    }
    .group-hover\:show-inline {
      display: inline-block !important;
    }
  }
}

.group {
  .item-visible-on-hover {
    opacity: 0;
  }
  &:hover,
  &:active {
    .item-visible-on-hover {
      opacity: 1;
    }
  }
}

.card-shadow {
  // box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.wmde-markdown {
  font-size: inherit !important;
}

@import "./raw-html-style";
